import { useEffect, useReducer, useCallback, useMemo } from 'react'
// utils
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client'
//
import { useSearchParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import {
  getTokenForPreauthorizationCode,
  requestEmailSignInLink,
  refreshAmbientAuthToken,
  refreshRootSession,
} from 'src/api/rest'
import {
  SWITCH_TO_DELEGATE_SESSION_MUTATION,
  getMeQuery,
  LOGOUT_MUTATION,
  SWITCH_USER_ACCOUNT_MUTATION,
} from 'src/graphql/queries'
import {
  getCredentials,
  getPersistantLoginSessionState,
  setCredentials,
  setPersistantLoginSessionState,
} from 'src/auth/utils'
import { useErrorContext } from 'src/components/error-context/error-context'
import { useAnalytics } from 'src/components/analytics'
import { useEmit, useSubscribe } from 'src/hooks/use-event-bus'
import { restartWebsocketConnection } from 'src/graphql/apolloClient'
import useBroadcastChannel from 'src/auth/hooks/use-broadcast-channel'
import { AuthContext } from './auth-context'
import { ActionMapType, AuthRolesType, AuthStateType, AuthSubscriptionStatusType, AuthUserType } from '../../types'

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGIN_WAIT = 'LOGIN_WAIT',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  FATAL_ERROR = 'FATAL_ERROR',
  REFRESH_SUCCESS = 'REFRESH_SUCCESS',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType | null
    roles: AuthRolesType
    subscriptionStatus: AuthSubscriptionStatusType
  }
  [Types.LOGIN]: {
    user: AuthUserType | null
  }
  [Types.REGISTER]: {
    user: AuthUserType | null
  }
  [Types.LOGIN_WAIT]: undefined
  [Types.LOGOUT]: undefined
  [Types.FATAL_ERROR]: undefined
  [Types.REFRESH_SUCCESS]: undefined
}

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>]

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
  roles: [],
  subscriptionStatus: null,
  fatalError: false,
}

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    const result: AuthStateType = {
      loading: false,
      user: action.payload.user,
      roles: action.payload.roles,
      subscriptionStatus: action.payload.subscriptionStatus,
      fatalError: false,
    }
    setPersistantLoginSessionState(result)
    return result
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      fatalError: false,
      user: action.payload.user,
    }
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    }
  }
  if (action.type === Types.LOGIN_WAIT) {
    return {
      ...state,
      loading: true,
      user: null,
    }
  }
  if (action.type === Types.LOGOUT) {
    const result: AuthStateType = {
      ...state,
      user: null,
      fatalError: false,
    }
    setPersistantLoginSessionState(undefined)
    return result
  }
  if (action.type === Types.FATAL_ERROR) {
    return {
      ...state,
      loading: false,
      fatalError: true,
    }
  }
  if (action.type === Types.REFRESH_SUCCESS) {
    return {
      ...state,
      loading: false,
      fatalError: false,
    }
  }
  return state
}

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode
}

export function AuthProvider({ children }: Props) {
  const { logMessage } = useErrorContext()
  const { identifyUser, track, onLogout } = useAnalytics()
  const { setUser: setErrorContextUser, logout: logoutErrorContext } = useErrorContext()
  const [state, dispatch] = useReducer(reducer, getPersistantLoginSessionState() ?? initialState)
  const [execGetMeQuery] = useLazyQuery(getMeQuery)
  const [searchParams] = useSearchParams()
  const apolloClient = useApolloClient()
  const [switchToDelegateSession] = useMutation(SWITCH_TO_DELEGATE_SESSION_MUTATION)
  const [switchUserAccount] = useMutation(SWITCH_USER_ACCOUNT_MUTATION)
  const [logoutUserAccount] = useMutation(LOGOUT_MUTATION)
  const { update, shutdown, boot, hide } = useIntercom()
  const initialize = useCallback(async () => {
    try {
      // Attempt to get the current user
      // If the token is expired, the refresh event will automatically trigger
      const res = await execGetMeQuery({
        fetchPolicy: 'network-only',
      })
      if (!res.data?.me) {
        throw new Error('No user found')
      }

      const user: AuthUserType = { ...res.data.me }
      const impersonator = res.data?.currentSession.impersonator
      if (impersonator) {
        user.impersonator = {
          id: impersonator?.id,
          orgId: impersonator?.orgId!,
          email: impersonator?.email!,
          name: impersonator?.name ?? impersonator?.email!,
        }
      }

      const identifiers = impersonator ?? user
      // set the user in the error context
      setErrorContextUser(identifiers.id, identifiers.email)

      // update intercom and hide the default launcher
      const customAttributes: { [key: string]: any } = {}
      if (user?.id) {
        customAttributes.ambient_user_id = identifiers.id
      }
      if (user?.orgId) {
        customAttributes.ambient_org_id = identifiers.orgId
      }
      update({
        hideDefaultLauncher: true,
        // ! do not set the userId here so that users are identified by email
        email: identifiers?.email ?? undefined,
        ...(!impersonator && {
          avatar: {
            type: 'avatar',
            imageUrl: user?.userProfile?.picUrl ?? undefined,
          },
        }),
        customAttributes,
      })
      hide()

      // identify the user in our analytics provider and track the authenticated event
      identifyUser(identifiers)

      const roles = res.data?.getMyActiveRoleNames
      const subStatus = res.data?.getMySubscriptionStatus
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: user ?? null,
          roles: roles ?? [],
          subscriptionStatus: subStatus ?? null,
        },
      })
    } catch (error) {
      setCredentials(null, null)
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          roles: [],
          subscriptionStatus: null,
        },
      })
    }
  }, [execGetMeQuery, hide, identifyUser, setErrorContextUser, update])

  /**
   * Reauthorization logic
   *
   * This logic uses an event bus to coordinate the reauthorization process.
   * 1. Other areas (such as a fetch or graphql client) of the app can trigger a reauthorization by emitting the 'auth:reauth' event.
   * 2. The reauth listener will then attempt to reauthorize the user.
   *    Success: If the reauthorization is successful, the listener will emit the 'auth:reauth:complete' event with the new tokens.
   *    Fail: If the reauthorization fails, the listener will emit the 'auth:reauth:failed' event.
   *
   * There is a handy utility function called *reauthenticate()* wraps this logic in a promise that resolves when the reauthorization is complete.
   */
  const reauthFail = useEmit('auth:reauth:failed')
  const reauthSuccess = useEmit('auth:reauth:complete')
  const reauthListener = useCallback(async () => {
    // use local storage to prevent multiple reauthentications being triggered
    // in the same render cycle
    if (localStorage.getItem('isReauthenticating') === 'true') {
      return
    }
    localStorage.setItem('isReauthenticating', 'true')
    try {
      if (!state.user) {
        logMessage('No user found when reauthenticating')
        reauthFail(undefined)
        dispatch({
          type: Types.FATAL_ERROR,
        })
        return
      }
      if (state.user?.impersonator) {
        // make sure the root session is refreshed
        try {
          await refreshRootSession()
        } catch (error) {
          logMessage('Error refreshing root session', error)
          reauthFail(undefined)
          dispatch({
            type: Types.FATAL_ERROR,
          })
          return
        }
        // // set the creds so that the webhook can authorize

        const res = await switchToDelegateSession({
          variables: {
            targetId: state.user.id,
            fromRootSession: true,
          },
          fetchPolicy: 'network-only',
        })
        const { delegateToken } = res.data?.switchToDelegateSession ?? {}
        if (!delegateToken) {
          console.debug('Error reauthenticating delegate session', res.errors)
          reauthFail(undefined)
          return
        }

        setCredentials(delegateToken.token, delegateToken.renewalToken ?? '')
        reauthSuccess({ accessToken: delegateToken.token, refreshToken: delegateToken.renewalToken ?? '' })
        restartWebsocketConnection(apolloClient)
      } else {
        try {
          const { accessToken, refreshToken } = await getCredentials()
          const refreshedCreds = await refreshAmbientAuthToken(accessToken ?? '', refreshToken ?? '')
          setCredentials(refreshedCreds.token, refreshedCreds.refreshToken)
          reauthSuccess({ accessToken: refreshedCreds.token, refreshToken: refreshedCreds.refreshToken })
          restartWebsocketConnection(apolloClient)
          dispatch({
            type: Types.REFRESH_SUCCESS,
          })
          initialize()
        } catch {
          reauthFail(undefined)
          dispatch({
            type: Types.FATAL_ERROR,
          })
        }
      }
    } finally {
      localStorage.removeItem('isReauthenticating')
    }
  }, [reauthSuccess, apolloClient, state.user, switchToDelegateSession, reauthFail, logMessage, initialize])
  useSubscribe('auth:reauth', reauthListener)

  // Never leave the user in a bad state if they refresh the page while reauthenticating
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('isReauthenticating')
    })
    return () => {
      localStorage.removeItem('isReauthenticating')
    }
  }, [])

  // Initialize the user on mount
  useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const requestEmail = useCallback(
    async (email: string) => {
      await requestEmailSignInLink(
        email,
        searchParams.get('returnTo') ?? searchParams.get('return') ?? searchParams.get('redirect_path') ?? undefined,
        searchParams.get('source') ?? undefined,
        searchParams.get('source_ref') ?? undefined,
        searchParams.get('trial_code') ?? undefined
      )
    },
    [searchParams]
  )

  // EXCHANGE CODE
  const exchangeCode = useCallback(
    async (code: string, nonce: string, subject: string) => {
      const res = await getTokenForPreauthorizationCode(
        code,
        nonce!,
        subject,
        searchParams.get('source') ?? undefined,
        searchParams.get('source_ref') ?? undefined,
        searchParams.get('trial_code') ?? undefined
      )

      // set the creds and let the initialize function handle the rest
      setCredentials(res.token, res.refreshToken)

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: null,
        },
      })
      initialize()
    },
    [initialize, searchParams]
  )

  // LOGIN WITH TOKEN
  const loginWithToken = useCallback(
    async (accessToken: string, refreshToken: string) => {
      // set the creds and let the initialize function handle the rest
      setCredentials(accessToken, refreshToken)

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: null,
        },
      })
      initialize()
    },
    [initialize]
  )

  // REGISTER
  const register = useCallback(async (email: string, password: string, firstName: string, lastName: string) => {
    // noop
  }, [])

  const emitAccountSwitch = useEmit('auth:switchuser')
  const { postMessage: broadcastSwitchAccount } = useBroadcastChannel(
    'auth:switchaccount',
    useCallback(
      async (event: Payload['INITIAL']) => {
        dispatch({
          type: Types.INITIAL,
          payload: { ...event },
        })
        restartWebsocketConnection(apolloClient)
        await apolloClient.resetStore()
        if (event.user && state.user) {
          emitAccountSwitch({
            fromUserId: state.user?.id,
            toUserId: event.user.id,
            delegateSession: !!event.user.impersonator || !!state.user?.impersonator,
          })
        }
        initialize()
      },
      [apolloClient, initialize, emitAccountSwitch, state.user]
    )
  )

  const switchAccounts = useCallback(
    async (userId: string, orgId: string, impersonation: boolean) => {
      // if this is an impersonation account switch, we will create a new session and set the creds
      // but not go through the normal initialize process since we are not logging out and also not
      // changing tracking data
      if (impersonation) {
        if (userId === state.user?.id) {
          return
        }

        const res = await switchToDelegateSession({
          variables: {
            targetId: userId,
            // if we are already impersonating, we need to tell the backend to use the root session
            // as the source of the impersonation
            fromRootSession: !!state.user?.impersonator,
          },
          fetchPolicy: 'network-only',
        })
        const { delegateToken } = res.data?.switchToDelegateSession ?? {}
        if (!delegateToken) {
          logMessage('Error creating delegate session. No tokens', res.errors)
          return
        }

        dispatch({
          type: Types.LOGIN_WAIT,
        })
        // set the creds so that the webhook can authorize
        setCredentials(delegateToken.token, delegateToken.renewalToken ?? '')

        // if this fails, we'll catch the error and send the user to the login page
        const meQueryResult = await execGetMeQuery({
          fetchPolicy: 'network-only',
        })
        if (res.errors || !meQueryResult.data?.me) {
          // throw an error in sentry
          logMessage('Error querying user details, switching to delegate account', res.errors)
          throw new Error('No user found')
        }

        let rootPrincipal: AuthUserType['impersonator'] | undefined
        const { impersonator } = meQueryResult.data.currentSession
        if (impersonator) {
          rootPrincipal = {
            id: impersonator.id,
            orgId: impersonator.orgId!,
            email: impersonator.email!,
            name: impersonator.name ?? impersonator.email!,
          }
        }
        const user: AuthUserType | undefined = { ...meQueryResult.data.me, impersonator: rootPrincipal }
        const roles = meQueryResult.data?.getMyActiveRoleNames
        const subStatus = meQueryResult.data?.getMySubscriptionStatus
        restartWebsocketConnection(apolloClient)
        await apolloClient.resetStore()
        const newState = {
          user: user ?? null,
          roles: roles ?? [],
          subscriptionStatus: subStatus ?? null,
        }
        if (user && state.user) {
          emitAccountSwitch({
            fromUserId: state.user.id,
            toUserId: user.id,
            delegateSession: !!user.impersonator || !!state.user.impersonator,
          })
        }
        broadcastSwitchAccount(newState)
        // dispatch aftewards now that state is updated
        dispatch({
          type: Types.INITIAL,
          payload: newState,
        })
      } else {
        // The order of operations:
        // 1. Switch the user account - the backend will set the cookies and return the new tokens
        // 2. Premptively refresh the session for the account we are switching to - this prevents us having to react to a 401 when getting info for the new account
        // 3. Get the new user info
        // 4. Reset the apollo store to ensure we have the correct data
        // 5. Restart the websocket connection - this will cause the websocket to reconnect with the new user's credentials

        const result = await switchUserAccount({
          variables: {
            userId,
            orgId,
          },
          fetchPolicy: 'network-only',
        })
        if (result.errors || !result.data?.switchUserAccount) {
          // throw an error in sentry
          throw new Error('Error switching accounts')
        }
        // Wait until after we know the switch was successful to tell the APP to show the loading screen
        dispatch({
          type: Types.LOGIN_WAIT,
        })
        const acountSwitchResults = result.data?.switchUserAccount
        // ! TODO: We don't need credentials for refreshing, since this is managed by server cookies
        // refresh the session
        const refreshedCreds = await refreshAmbientAuthToken(
          acountSwitchResults.accessToken ?? '',
          acountSwitchResults.refreshToken ?? ''
        )
        setCredentials(refreshedCreds.token, refreshedCreds.refreshToken)

        // if this fails, we'll catch the error and send the user to the login page
        const meQueryResult = await execGetMeQuery({
          fetchPolicy: 'network-only',
        })
        if (meQueryResult.error || !meQueryResult.data?.me) {
          // throw an error in sentry
          throw new Error('No user found')
        }

        const user: AuthUserType | undefined = { ...meQueryResult.data.me, impersonator: undefined }
        const roles = meQueryResult.data?.getMyActiveRoleNames
        const subStatus = meQueryResult.data?.getMySubscriptionStatus
        restartWebsocketConnection(apolloClient)
        await apolloClient.resetStore()
        const newState = {
          user: user ?? null,
          roles: roles ?? [],
          subscriptionStatus: subStatus ?? null,
        }
        if (user && state.user) {
          emitAccountSwitch({
            fromUserId: state.user.id,
            toUserId: user.id,
            delegateSession: !!user.impersonator || !!state.user.impersonator,
          })
        }
        broadcastSwitchAccount(newState)
        // dispatch aftewards now that state is updated
        dispatch({
          type: Types.INITIAL,
          payload: newState,
        })
      }
    },
    [
      state.user,
      switchToDelegateSession,
      execGetMeQuery,
      apolloClient,
      emitAccountSwitch,
      broadcastSwitchAccount,
      logMessage,
      switchUserAccount,
    ]
  )

  // LOGOUT
  const emitLogout = useEmit('auth:logout')
  const logout = useCallback(async () => {
    try {
      if (state.user?.id) {
        emitLogout({ userId: state.user.id })
      }
      // track the logout event
      track('Logout', {})
      // clear the user in the error context
      logoutErrorContext()
      // shutdown intercom and reboot so it works for the next login session
      shutdown()
      boot()
      // shut down the analytics provider
      onLogout()
    } finally {
      // clear all data on logout
      await logoutUserAccount()
      setCredentials(null, null)
      apolloClient.clearStore()
      apolloClient.cache.gc()
    }
    dispatch({
      type: Types.LOGOUT,
    })
  }, [apolloClient, boot, logoutErrorContext, logoutUserAccount, onLogout, shutdown, track, state.user?.id, emitLogout])

  // ----------------------------------------------------------------------

  const testFatalError = useCallback(async () => {
    dispatch({
      type: Types.FATAL_ERROR,
    })
  }, [dispatch])

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated'
  const status = state.loading ? 'loading' : checkAuthenticated

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      roles: state.roles,
      subscriptionStatus: state.subscriptionStatus,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      fatalError: state.fatalError,
      //
      requestEmail,
      exchangeCode,
      register,
      logout,
      testFatalError,
      loginWithToken,
      switchAccounts,
    }),
    [
      state.user,
      state.roles,
      state.subscriptionStatus,
      state.fatalError,
      status,
      requestEmail,
      exchangeCode,
      register,
      logout,
      loginWithToken,
      switchAccounts,
      testFatalError,
    ]
  )

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
