import { useSubscribe } from 'src/hooks/use-event-bus'
import { SEARCHBAR_HISTORY_LOCAL_STORAGE_KEY } from './types'

/**
 * This component will handle cleaning up state when the user logs out or the session expires.
 */
export function SearchbarAuthEventListener() {
  // when a user logs out remove persisted filter
  useSubscribe('auth:logout', ({ userId }) => {
    localStorage.removeItem(SEARCHBAR_HISTORY_LOCAL_STORAGE_KEY)
  })

  return null
}
