import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Iconify from 'src/components/iconify'
import { useBoolean } from 'src/hooks/use-boolean'
import ContactUsCalendlyDialog from './contact-us-calendly'

interface FreeTrialEndedBannerProps {
  extraBodyContent?: React.ReactNode
}

export default function FreeTrialEndedBanner({ extraBodyContent }: FreeTrialEndedBannerProps) {
  const calendlyDialog = useBoolean()

  return (
    <>
      <Stack sx={{ backgroundColor: 'warning.light', borderRadius: 1, p: 1 }} flexDirection="row" alignItems="top">
        <Stack p={1}>
          <Iconify color="warning.darker" icon="mdi:alert-circle" width={22} />
        </Stack>
        <Stack alignItems="start" flexWrap="wrap" color="warning.darker" spacing={1} py={1}>
          <Typography variant="subtitle1" color="warning.darker">
            Your free trial has ended and you can no longer record meetings.
          </Typography>
          {extraBodyContent}
          <Button
            sx={{ padding: 0 }}
            color="warning"
            endIcon={<Iconify color="warning.darker" icon="solar:arrow-right-linear" />}
            onClick={calendlyDialog.onTrue}
          >
            <Typography variant="body2" color="warning.darker" fontWeight={800}>
              Contact us to start a plan
            </Typography>
          </Button>
        </Stack>
      </Stack>
      <ContactUsCalendlyDialog open={calendlyDialog.value} onClose={calendlyDialog.onFalse} />
    </>
  )
}
