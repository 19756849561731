import { useMemo } from 'react'

const isMac = navigator.userAgent.includes('Mac')
const SHORTCUT_KEYS = {
  meta: isMac ? '⌘' : '⊞',
  shift: isMac ? '⇧' : 'Shift',
  alt: isMac ? '⌥' : 'Alt',
  ctrl: isMac ? '⌃' : 'Ctrl',
}

interface KeyboardShortcutContentProps {
  shortcut: string
  meta?: boolean
  shift?: boolean
  alt?: boolean
  ctrl?: boolean
}

export function KeyboardShortcutContent({ shortcut, meta, shift, alt, ctrl }: KeyboardShortcutContentProps) {
  const content = useMemo(() => {
    const keys = []
    if (meta) keys.push(SHORTCUT_KEYS.meta)
    if (shift) keys.push(SHORTCUT_KEYS.shift)
    if (alt) keys.push(SHORTCUT_KEYS.alt)
    if (ctrl) keys.push(SHORTCUT_KEYS.ctrl)
    return [...keys, shortcut].join(' ')
  }, [shortcut, meta, shift, alt, ctrl])
  return content
}
