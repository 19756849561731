// @mui
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
// components
import { useEffect, useRef } from 'react'
import Label from 'src/components/label'
import { Avatar, Chip, ListItemIcon, Stack, Typography } from '@mui/material'
import { fDateTime } from 'src/utils/format-time'

// ----------------------------------------------------------------------

type Props = {
  icon?: React.ReactNode
  title: {
    text: string
    highlight: boolean
  }[]
  createdAt: string
  updatedAt: string
  owner: {
    name: string
    picUrl: string
  } | null
  groupLabel: string
  onClickItem: VoidFunction
  isSelected?: boolean
}

export default function ResultItem({
  icon,
  title,
  createdAt,
  updatedAt,
  owner,
  groupLabel,
  onClickItem,
  isSelected,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({ block: 'nearest' })
      ref.current.focus()
    }
  }, [isSelected])

  return (
    <ListItemButton
      ref={ref}
      onClick={onClickItem}
      sx={{
        '&:focus': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity * 2),
        },
        '&:hover': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primaryTypographyProps={{
          typography: 'body2',
          sx: { textTransform: 'capitalize' },
        }}
        secondaryTypographyProps={{ typography: 'caption' }}
        primary={title.map((part, index) => (
          <Box
            key={index}
            component="span"
            sx={{
              fontWeight: part.highlight ? 'bold' : 'normal',
            }}
          >
            {part.text}
          </Box>
        ))}
        secondary={
          <Stack direction="row" alignItems="center" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="caption">Created</Typography>
              <Typography variant="caption">{fDateTime(createdAt)}</Typography>
            </Stack>
            {owner && (
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* <Typography variant="caption">Author</Typography> */}
                <Chip
                  variant="soft"
                  label={owner.name}
                  size="small"
                  avatar={<Avatar src={owner.picUrl} sx={{ width: 16, height: 16 }} alt={owner.name} />}
                />
              </Stack>
            )}
          </Stack>
        }
      />
      {groupLabel && <Label color="info">{groupLabel}</Label>}
    </ListItemButton>
  )
}
