import React, { useRef, useEffect } from 'react'
import { ListItemButton, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, alpha } from '@mui/material'
import Iconify from 'src/components/iconify'

type RecentSearchItemProps = {
  query: string
  isSelected: boolean
  onClickItem: () => void
  onRemove: () => void
}

export default function RecentSearchItem({ query, isSelected, onClickItem, onRemove }: RecentSearchItemProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({ block: 'nearest' })
      ref.current.focus()
    }
  }, [isSelected])

  return (
    <ListItemButton
      ref={ref}
      onClick={onClickItem}
      sx={{
        '&:focus': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity * 2),
        },
        '&:hover': {
          backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }}
    >
      <ListItemIcon sx={{ mr: 2 }}>
        <Iconify color="text.secondary" width={18} icon="tabler:search" />
      </ListItemIcon>

      <ListItemText
        primary={query}
        primaryTypographyProps={{
          typography: 'body1',
        }}
      />

      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            onRemove()
          }}
          sx={{
            opacity: 0,
            transition: (theme) => theme.transitions.create('opacity'),
            '.MuiListItemButton-root:hover &': {
              opacity: 0.48,
            },
            '&:hover': {
              opacity: '1 !important',
            },
          }}
        >
          <Iconify icon="tabler:x" width={16} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItemButton>
  )
}
