import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
// layouts
import CompactLayout from 'src/layouts/compact'
// components
import { SplashScreen } from 'src/components/loading-screen'
import { AuthGuard } from 'src/auth/guard'

// ----------------------------------------------------------------------

const SlackConnectedPage = lazy(() => import('src/pages/integration/slack-connected-page'))
const CalendarConnectedPage = lazy(() => import('src/pages/integration/calendar-connected-page'))
const CalendarConnectionFailedPage = lazy(() => import('src/pages/integration/calendar-connection-failed-page'))
// ----------------------------------------------------------------------

/**
 * These routes handle termination points for integration installation.
 * It's usually at the end of the OAuth flow.
 */
export const integrationRoutes = [
  {
    path: 'integrations',
    element: (
      <AuthGuard>
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'slack/connected', element: <SlackConnectedPage /> },
      { path: 'calendar/connected', element: <CalendarConnectedPage /> },
      { path: 'calendar/connection-failed', element: <CalendarConnectionFailedPage /> },
    ],
  },
]
