import React, { useState, memo, useCallback, useRef, useEffect, useMemo } from 'react'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
// @mui
import { useTheme, alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import InputBase from '@mui/material/InputBase'
import InputAdornment from '@mui/material/InputAdornment'
import Dialog, { dialogClasses } from '@mui/material/Dialog'
// hooks
import ListItemButton from '@mui/material/ListItemButton'
import { useQuery } from '@apollo/client'
import ListItemText from '@mui/material/ListItemText'
import { useSnackbar } from 'notistack'
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  InputLabel,
  ListItem,
  Paper,
  PaperProps,
  Typography,
} from '@mui/material'
import { useBoolean } from 'src/hooks/use-boolean'
import { useEventListener } from 'src/hooks/use-event-listener'
// components
import Label from 'src/components/label'
import Iconify from 'src/components/iconify'
import Scrollbar from 'src/components/scrollbar'
import SearchNotFound from 'src/components/search-not-found'
//
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard'
import { getMeQuery, QUICK_SEARCH_QUERY } from 'src/graphql/queries'
import { getCredentials } from 'src/auth/utils'
import { useEmit, useSubscribe } from 'src/hooks/use-event-bus'
import { useLocalStorage } from 'src/hooks/use-local-storage'
import { useNavigate } from 'react-router'
import { useAuthContext } from 'src/auth/hooks/use-auth-context'
import { paths } from 'src/routes/paths'
import { PostSource, QuickSearchQuery } from 'src/__generated__/graphql'
import { useDebounceValue } from 'usehooks-ts'
import { useResponsive } from 'src/hooks/use-responsive'
import { KeyboardShortcutContent } from 'src/components/keyboard-shortcut/keyboard-shortcut'
import ResultItem from './result-item'
import RecentSearchItem from './recent-search-item'
import { SEARCHBAR_HISTORY_LOCAL_STORAGE_KEY } from './types'

// ----------------------------------------------------------------------

type SearchbarResultState = 'notFound' | 'debug' | 'search' | 'debug:internal'
const MAX_SEARCH_HISTORY = 10
const MAX_SEARCH_RESULTS_PER_TYPE = 6

function Searchbar() {
  const mdUp = useResponsive('up', 'md')
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(SEARCHBAR_HISTORY_LOCAL_STORAGE_KEY, [])
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [selectedItem, setSelectedItem] = useState<number>(-1)
  const theme = useTheme()
  const navigate = useNavigate()
  const search = useBoolean()
  const [searchQuery, setSearchQuery] = useState('')
  const [projectSearchResults, setProjectSearchResults] = useState<QuickSearchQuery['quickSearchProjects']>([])
  const [postSearchResults, setPostSearchResults] = useState<QuickSearchQuery['quickSearchPosts']>([])
  const [hasMorePosts, setHasMorePosts] = useState(false)
  const [hasMoreProjects, setHasMoreProjects] = useState(false)
  const [debouncedSearchQuery] = useDebounceValue(searchQuery, 200, {
    maxWait: 300,
  })

  const { loading: searchLoading } = useQuery(QUICK_SEARCH_QUERY, {
    variables: {
      query: debouncedSearchQuery.trim(),
      limit: MAX_SEARCH_RESULTS_PER_TYPE + 1, // the extra 1 is to check if there are more results
    },
    // Only run query if search query is 2+ characters
    skip: !debouncedSearchQuery || debouncedSearchQuery.trim().length < 2,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProjectSearchResults(data?.quickSearchProjects.slice(0, MAX_SEARCH_RESULTS_PER_TYPE) ?? [])
      setPostSearchResults(data?.quickSearchPosts.slice(0, MAX_SEARCH_RESULTS_PER_TYPE) ?? [])
      setHasMorePosts(data?.quickSearchPosts?.length > MAX_SEARCH_RESULTS_PER_TYPE)
      setHasMoreProjects(data?.quickSearchProjects?.length > MAX_SEARCH_RESULTS_PER_TYPE)
    },
  })

  const handleClose = useCallback(() => {
    search.onFalse()
  }, [search])

  const moveSelectedItem = useCallback(
    (delta: number) => {
      const recentSearchCount =
        !searchQuery || searchQuery.length < 2 ? Math.min(searchHistory.length, MAX_SEARCH_HISTORY) : 0
      const totalItems = recentSearchCount + projectSearchResults.length + postSearchResults.length

      if (delta < 0) {
        const newIndex = selectedItem - 1
        const constrainedIndex = Math.max(-1, newIndex)
        if (constrainedIndex === -1) {
          inputRef.current?.focus()
        }
        setSelectedItem(constrainedIndex)
      } else if (delta > 0) {
        const newIndex = selectedItem + 1
        const constrainedIndex = Math.min(newIndex, totalItems - 1)
        setSelectedItem(constrainedIndex)
      }
    },
    [projectSearchResults, postSearchResults, selectedItem, searchQuery, searchHistory.length]
  )

  const emitRunDebugCmd = useEmit('debugcmd:run')

  const saveSearchQuery = useCallback(
    (query: string) => {
      if (!query || query.length < 2) return

      setSearchHistory((prev) => {
        // Don't add if it's the same as the most recent search
        if (prev[0] === query) {
          return prev
        }

        const newHistory = [...prev]
        newHistory.unshift(query)
        // restrict length to 10 items
        newHistory.splice(10)
        return newHistory
      })
    },
    [setSearchHistory]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'k' && event.metaKey) {
        search.onToggle()
        setSelectedItem(-1)
      }

      // don't handle key events if the searchbar is not open
      if (!search.value) {
        return
      }

      if (event.key === 'ArrowDown' || (event.key === 'Enter' && selectedItem !== -1)) {
        // scroll through the list of results and focus the previous/next one
        moveSelectedItem(1)
        return
      }

      if (event.key === 'ArrowUp') {
        moveSelectedItem(-1)
        return
      }

      if (event.key === 'Enter') {
        // handle search if the selected item is not -1
        let isUrl = false
        try {
          // eslint-disable-next-line no-new
          new URL(searchQuery)
          isUrl = true
        } catch (e) {
          // not a url
        }
        if (isUrl) {
          const url = new URL(searchQuery)
          if (url.hostname === window.location.hostname) {
            navigate(url.pathname)
            handleClose()
            return
          }
        }
        saveSearchQuery(searchQuery)
        if (searchQuery.startsWith('$')) {
          const command = /^\$\s*(.*)$/.exec(searchQuery)
          if (command?.[1]) {
            // debug command
            emitRunDebugCmd({ cmd: command?.[1] })
            return
          }
        }

        setSelectedItem(0)
      }

      // Save search when using arrows to navigate away from input
      if ((event.key === 'ArrowDown' || event.key === 'ArrowUp') && selectedItem === -1) {
        saveSearchQuery(searchQuery)
      }
    },
    [search, selectedItem, moveSelectedItem, saveSearchQuery, searchQuery, navigate, handleClose, emitRunDebugCmd]
  )
  useEventListener('keydown', handleKeyDown)

  const handleClick = useCallback(
    (path: string) => {
      saveSearchQuery(searchQuery)
      // clear the search query
      setSearchQuery('')
      handleClose()

      if (path.includes('http')) {
        window.open(path)
      } else {
        navigate(path)
      }
    },
    [navigate, saveSearchQuery, searchQuery, handleClose]
  )

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value)
    if (event.target.value.length < 2) {
      setPostSearchResults([])
      setProjectSearchResults([])
      setHasMorePosts(false)
      setHasMoreProjects(false)
    }
  }, [])

  const handleRemoveSearchItem = useCallback(
    (indexToRemove: number) => {
      setSearchHistory((prev) => prev.filter((_, index) => index !== indexToRemove))
    },
    [setSearchHistory]
  )

  const handleClearSearchHistory = useCallback(() => {
    setSearchHistory([])
  }, [setSearchHistory])

  const renderSearchResults = useMemo(() => {
    // Show recent searches if query is empty or very short
    if (!searchQuery || debouncedSearchQuery.length < 2 || searchQuery.length < 2) {
      if (searchHistory.length > 0) {
        return (
          <List disablePadding>
            <ListItem
              secondaryAction={
                <Button
                  startIcon={<Iconify icon="tabler:trash" width={16} />}
                  variant="text"
                  size="small"
                  onClick={handleClearSearchHistory}
                >
                  Clear
                </Button>
              }
            >
              <Typography variant="subtitle2">Recent Searches</Typography>
            </ListItem>
            {searchHistory.slice(0, MAX_SEARCH_HISTORY).map((query, index) => (
              <RecentSearchItem
                key={`recent-${index}`}
                query={query}
                isSelected={selectedItem === index}
                onClickItem={() => {
                  setSearchQuery(query)
                  inputRef.current?.focus()
                }}
                onRemove={() => handleRemoveSearchItem(index)}
              />
            ))}
          </List>
        )
      }
    }

    const hasProjects = projectSearchResults.length > 0
    const hasPosts = postSearchResults.length > 0

    // use the debounced search query to avoid flickering when the search query is empty
    if (!hasProjects && !hasPosts && searchQuery.length >= 2 && debouncedSearchQuery.length >= 2) {
      return <SearchNotFound query={searchQuery} sx={{ py: 10 }} />
    }

    return (
      <List disablePadding>
        {hasPosts && (
          <>
            <ListItem>
              <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2">Posts</Typography>
                {hasMorePosts && (
                  <Typography variant="caption" color="text.secondary">
                    Showing top {MAX_SEARCH_RESULTS_PER_TYPE} results
                  </Typography>
                )}
              </Stack>
            </ListItem>
            {postSearchResults.map((post, index) => {
              const partsTitle = parse(
                post.title,
                match(post.title, searchQuery, { findAllOccurrences: true, insideWords: true })
              )
              const isSelected = index === selectedItem
              let icon = 'tabler:file'
              if (post.postSource === PostSource.Email) {
                icon = 'tabler:mail'
              } else if (post.postSource === PostSource.Slack) {
                icon = 'tabler:brand-slack'
              } else if (post.postSource === PostSource.Meeting) {
                icon = 'tabler:calendar-event'
              }
              return (
                <ResultItem
                  key={post.id}
                  icon={<Iconify width={24} icon={icon} />}
                  title={partsTitle}
                  createdAt={post.createdAt}
                  updatedAt={post.updatedAt}
                  owner={post.author ? { name: post.author.name, picUrl: post.author.picUrl ?? '' } : null}
                  groupLabel=""
                  isSelected={isSelected}
                  onClickItem={() => handleClick(paths.dashboard.post.details(post.id))}
                />
              )
            })}
            <Divider />
          </>
        )}
        {hasProjects && (
          <>
            <ListItem>
              <Stack width={1} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2">Projects</Typography>
                {hasMoreProjects && (
                  <Typography variant="caption" color="text.secondary">
                    Showing top {MAX_SEARCH_RESULTS_PER_TYPE} results
                  </Typography>
                )}
              </Stack>
            </ListItem>
            {projectSearchResults.map((project, index) => {
              const partsTitle = parse(
                project.name,
                match(project.name, searchQuery, { findAllOccurrences: true, insideWords: true })
              )
              const isSelected = index === selectedItem - postSearchResults.length
              return (
                <ResultItem
                  key={project.id}
                  icon={<Iconify width={24} icon="tabler:folder" />}
                  title={partsTitle}
                  createdAt={project.createdAt}
                  updatedAt={project.updatedAt}
                  owner={project.owner ? { name: project.owner.name, picUrl: project.owner.picUrl ?? '' } : null}
                  groupLabel=""
                  isSelected={isSelected}
                  onClickItem={() => handleClick(paths.dashboard.projects.details(project.id))}
                />
              )
            })}
          </>
        )}
      </List>
    )
  }, [
    searchQuery,
    projectSearchResults,
    postSearchResults,
    debouncedSearchQuery,
    searchHistory,
    handleClearSearchHistory,
    selectedItem,
    handleRemoveSearchItem,
    handleClick,
    hasMorePosts,
    hasMoreProjects,
  ])

  let resultState: SearchbarResultState = 'search'
  if (searchQuery.startsWith('$')) {
    resultState = 'debug:internal'
  } else if (projectSearchResults.length === 0 && postSearchResults.length === 0) {
    resultState = 'notFound'
  }

  const renderButton = (
    <Button
      className="override-todesktop-drag"
      intercom-data-attribute="toggle-searchbar"
      onClick={search.onTrue}
      disableRipple
      sx={{
        minWidth: 10,
        mx: 0,
        borderRadius: 5,
      }}
      variant="soft"
    >
      <Iconify icon="eva:search-fill" />
      {mdUp && (
        <Label sx={{ ml: 2, fontSize: 12, color: 'text.secondary', cursor: 'pointer' }}>
          <KeyboardShortcutContent shortcut="K" meta />
        </Label>
      )}
    </Button>
  )

  const searchIsLocalUrl = searchQuery.startsWith(window.location.origin)
  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="md"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
            maxHeight: '90%',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <InputBase
            inputRef={inputRef}
            fullWidth
            autoFocus
            placeholder="Search posts, projects, etc..."
            value={searchQuery}
            onChange={handleSearch}
            // clear the selected item when the input is focused
            onFocus={() => setSelectedItem(-1)}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Fade
                  in={searchLoading}
                  timeout={{
                    appear: 50,
                    enter: 50,
                    exit: 1000,
                  }}
                >
                  <CircularProgress thickness={5} size={20} color="primary" />
                </Fade>
                <Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>
              </Stack>
            }
            inputProps={{
              sx: { typography: 'h6' },
            }}
            sx={{
              backgroundColor: 'background.neutral',
              p: 1,
              borderRadius: 0.6,
            }}
          />
        </Box>
        <Scrollbar tabIndex={undefined} sx={{ p: 0, minHeight: 400 }}>
          {searchIsLocalUrl && <GoToUrl url={searchQuery} />}
          {resultState === 'debug:internal' && <DebugOptions selectedItem={selectedItem ?? -1} />}
          {resultState !== 'debug:internal' && renderSearchResults}
        </Scrollbar>
      </Dialog>
    </>
  )
}

function DebugOptions({ selectedItem }: { selectedItem: number }) {
  const emitFeatureUpdated = useEmit('feature:updated')
  const { data, loading } = useQuery(getMeQuery, {
    fetchPolicy: 'cache-first',
  })
  const { copy } = useCopyToClipboard()
  const { enqueueSnackbar } = useSnackbar()
  const { testFatalError } = useAuthContext()

  const copyText = useCallback(
    (text: string) => {
      copy(text)
      enqueueSnackbar('Copied to clipboard', { variant: 'success' })
    },
    [copy, enqueueSnackbar]
  )

  const listFeatures = useCallback(() => {
    const features = Object.keys(localStorage)
      .filter((key) => key.startsWith('feature:'))
      .sort()
      .map((key) => `${key.replace('feature:', '')}=${localStorage.getItem(key)}`)
    return features.join(', ')
  }, [])

  const showCommandOutput = useCallback(
    (message?: string, details?: React.ReactNode) => {
      enqueueSnackbar({
        message: (
          <Stack spacing={1} width={1}>
            {message && <Typography variant="overline">{message}</Typography>}
            <Box width={1} p={1} borderRadius={1}>
              {details}
            </Box>
          </Stack>
        ),
        autoHideDuration: 7000,
        variant: 'default',
        transitionDuration: {
          enter: 50,
          exit: 50,
        },
      })
    },
    [enqueueSnackbar]
  )

  const creds = getCredentials()

  const processCommand = useCallback(
    (command: string) => {
      const [commandName, ...args] = command.split(' ')
      if (commandName === 'help') {
        showCommandOutput(
          'Available commands:',
          <Stack spacing={1}>
            <Typography variant="body2" component="div">
              <code>feature on|off|list</code>
            </Typography>
            <Typography variant="body2" component="div">
              <code>test-auth-fatal-error</code>
            </Typography>
          </Stack>
        )
      } else if (commandName === 'feature') {
        if (args[0] === 'on') {
          localStorage.setItem(`feature:${args[1]}`, 'true')
          emitFeatureUpdated({ feature: args[1], enabled: true })
          showCommandOutput('feature turned on. Enabled features:', listFeatures())
        } else if (args[0] === 'off') {
          localStorage.removeItem(`feature:${args[1]}`)
          emitFeatureUpdated({ feature: args[1], enabled: false })
          showCommandOutput('feature turned off. Enabled features:', listFeatures())
        } else if (args[0] === 'list') {
          showCommandOutput('listing features', listFeatures())
        }
      } else if (commandName === 'test-auth-fatal-error') {
        testFatalError()
        showCommandOutput('Triggering fatal error...')
      }
    },
    [emitFeatureUpdated, listFeatures, showCommandOutput, testFatalError]
  )

  useSubscribe('debugcmd:run', ({ cmd }) => {
    processCommand(cmd)
  })

  if (loading) {
    return <List disablePadding>... loading ...</List>
  }

  return (
    <>
      <ListItem key="commands">
        <Typography variant="overline" mr={5}>
          Commands
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {`Type a command in the search box, use 'help' for a list of commands. Example: `}
          <code>$feature list</code>
        </Typography>
      </ListItem>
      <List disablePadding>
        <DebugItem
          isSelected={selectedItem === 0}
          key="0"
          onClickItem={() => copyText(data?.me?.id ?? '')}
          title="user id"
        >
          {data?.me?.id}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 1}
          key="1"
          onClickItem={() => copyText(data?.me?.orgId ?? '')}
          title="org id"
        >
          {data?.me?.orgId}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 2}
          key="2"
          onClickItem={() => copyText(data?.me?.email ?? '')}
          title="email"
        >
          {data?.me?.email}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 3}
          key="3"
          onClickItem={() => copyText(data?.getMyActiveRoleNames.join(',') ?? '')}
          title="roles"
        >
          {data?.getMyActiveRoleNames.join(',')}
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 3}
          key="5"
          onClickItem={() => copyText(JSON.stringify(data?.getMySubscriptionStatus ?? '{}'))}
          title="subscription"
        >
          <Stack>
            <Stack direction="row" spacing={2}>
              <InputLabel>Product</InputLabel>
              {data?.getMySubscriptionStatus?.productName}
            </Stack>
            <Stack direction="row" spacing={2}>
              <InputLabel>Is trial</InputLabel>
              {data?.getMySubscriptionStatus?.isTrial ? 'true' : 'false'}
            </Stack>
            <Stack direction="row" spacing={2}>
              <InputLabel>End Date</InputLabel>
              {data?.getMySubscriptionStatus?.endDate}
            </Stack>
          </Stack>
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 4}
          key="6"
          onClickItem={() => copyText(creds.accessToken ?? '')}
          title="access token"
        >
          <Typography sx={{ lineBreak: 'anywhere' }}>{creds.accessToken}</Typography>
        </DebugItem>
        <DebugItem
          isSelected={selectedItem === 4}
          key="7"
          onClickItem={() => copyText(creds.refreshToken ?? '')}
          title="refesh token"
        >
          <Typography sx={{ lineBreak: 'anywhere' }}>{creds.refreshToken}</Typography>
        </DebugItem>
      </List>
    </>
  )
}

function DebugItem({
  title,
  onClickItem,
  isSelected,
  children,
}: {
  title: string
  onClickItem: () => void
  isSelected: boolean
  children: React.ReactNode
}) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && isSelected) {
      ref.current.scrollIntoView({ block: 'nearest' })
      ref.current.focus()
    }
  }, [isSelected])
  return (
    <ListItemButton
      key={title}
      ref={ref}
      onClick={onClickItem}
      sx={{
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'transparent',
        borderBottomColor: (theme) => theme.palette.divider,
        '&:hover, &:focus': {
          borderRadius: 1,
          borderColor: (theme) => theme.palette.primary.main,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box minWidth={100}>
          <ListItemText
            primaryTypographyProps={{
              typography: 'subtitle2',
              sx: { textTransform: 'capitalize' },
            }}
            secondaryTypographyProps={{ typography: 'caption' }}
            primary={title}
          />
        </Box>
        <Box>{children}</Box>
      </Stack>
    </ListItemButton>
  )
}

interface GoToUrlProps extends PaperProps {
  url?: string
}

export function GoToUrl({ url, sx, ...other }: GoToUrlProps) {
  const navigate = useNavigate()

  let parsedUrl: URL | undefined
  try {
    // eslint-disable-next-line no-new
    parsedUrl = new URL(url ?? '')
  } catch (error) {
    // noop
  }

  if (!parsedUrl) {
    return null
  }

  if (window.location.toString() === parsedUrl.toString()) {
    return (
      <Paper sx={{ p: 2, textAlign: 'center', ...sx }} {...other}>
        You are currently on this page.
      </Paper>
    )
  }

  return (
    <Paper
      sx={{
        p: 4,
        pt: 2,
        bgcolor: 'unset',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
      onClick={() => {
        if (parsedUrl?.pathname) {
          navigate(parsedUrl.pathname)
        }
      }}
    >
      <Stack alignItems="center" spacing={1} width={1}>
        <Typography variant="body1" whiteSpace="nowrap">
          Use <strong>Enter</strong> or click to navigate to page
        </Typography>
        <Chip
          icon={<Iconify width={24} icon="tabler:link" />}
          variant="soft"
          label={parsedUrl.pathname}
          clickable
          // sx={{ width: 1 }}
        />
      </Stack>
    </Paper>
  )
}

export default memo(Searchbar)
