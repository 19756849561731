import { gql } from 'src/__generated__/gql'

/**
 * This query is used to get the current user's information and settings
 * It is composed of several sub-queries
 */
export const getMeQuery = gql(/* GraphQL */ `
  query GetMeQuery {
    me {
      email
      id
      orgId
      name
      userIntegrations {
        id
        type
        externalId
        createdAt
        updatedAt
        status
      }
      userProfile {
        userId
        firstname
        lastname
        picUrl
        createdAt
        updatedAt
      }
      personalizedAppSettings {
        settings
        createdAt
        updatedAt
      }
    }
    currentSession {
      impersonator {
        email
        id
        orgId
        name
      }
    }
    getNavigation {
      recurringMeetings {
        id
        title
        category
      }
    }
    getMyEnabledFeatureFlags
    getMyActiveRoleNames
    getMySubscriptionStatus {
      productName
      endDate
      isTrial
    }
    isDelegationEnabled
  }
`)

export const GET_NAVIGATION_QUERY = gql(/* GraphQL */ `
  query GetNavigation {
    getNavigation {
      recurringMeetings {
        id
        title
        category
      }
    }
  }
`)

export const SWITCH_USER_ACCOUNT_MUTATION = gql(/* GraphQL */ `
  mutation SwitchUserAccount($userId: String!, $orgId: String!) {
    switchUserAccount(userId: $userId, orgId: $orgId) {
      id
      orgId
      accessToken
      refreshToken
    }
  }
`)

export const LOGOUT_MUTATION = gql(/* GraphQL */ `
  mutation logoutUserAccount {
    logoutUserAccount
  }
`)

// used to get the user's integrations specifically
export const getMyIntegrationsQuery = gql(/* GraphQL */ `
  query GetMyIntegrations {
    me {
      userIntegrations {
        id
        type
        externalId
        createdAt
        updatedAt
        status
      }
    }
  }
`)

// A query to get the user's personalized app settings
export const GetMyAppSettings = gql(/* GraphQL */ `
  query MyAppSettings {
    myAppSettings {
      createdAt
      updatedAt
      settings
    }
  }
`)

// A query to update the user's personalized app settings
export const UpdateMyAppSettings = gql(/* GraphQL */ `
  mutation UpdateMyAppSettings($settings: JSON!) {
    updateMyAppSettings(settings: $settings) {
      createdAt
      updatedAt
      settings
    }
  }
`)

export const getRecallCalendarQuery = gql(/* GraphQL */ `
  query GetRecallCalendar {
    getRecallCalendar {
      record_non_host
      record_recurring
      record_external
      record_internal
      record_confirmed
      record_only_host
    }
  }
`)

export const getUserIntegrationsQuery = gql(/* GraphQL */ `
  query GetUserIntegrations {
    userIntegrations {
      id
      type
      externalId
      createdAt
      updatedAt
      status
    }
  }
`)

export const getIntegrationsQuery = gql(/* GraphQL */ `
  query GetIntegrations {
    integrations {
      id
      type
      connected
      status
      externalId
      userSettings
    }
  }
`)

export const deleteRecallCalendarMutation = gql(/* GraphQL */ `
  mutation DeleteRecallCalendar {
    deleteRecallCalendar
  }
`)

export const updateAutoRecordSettingsMutation = gql(/* GraphQL */ `
  mutation UpdateAutoRecordSettings($recordingSettings: JSON!) {
    updateAutoRecordSettings(recordingSettings: $recordingSettings) {
      record_non_host
      record_recurring
      record_external
      record_internal
      record_confirmed
      record_only_host
    }
  }
`)

export const initIntegrationMutation = gql(/* GraphQL */ `
  mutation InitIntegration($type: IntegrationType!) {
    initializeIntegration(type: $type) {
      id
      type
      connected
      status
      externalId
      userSettings
    }
  }
`)

export const createApiKeyMutation = gql(/* GraphQL */ `
  mutation CreateApiKey($platform: IntegrationType!, $label: String!) {
    createApiKey(platform: $platform, label: $label) {
      integration {
        type
      }
      key
      createdAt
      isDisabled
      label
    }
  }
`)

export const provisionIntegrationInstallToken = gql(/* GraphQL */ `
  query IntegrationInstallToken($integrationId: String!) {
    integrationInstallToken(integrationId: $integrationId)
  }
`)

export const createPostFeedbackMutation = gql(/* GraphQL */ `
  mutation CreatePostFeedback($postId: String!, $wasHelpful: Boolean!) {
    createPostFeedback(postId: $postId, wasHelpful: $wasHelpful)
  }
`)

export const updateIntegrationUserSettingsMutation = gql(/* GraphQL */ `
  mutation UpdateIntegrationUserSettings($id: String!, $userSettings: JSON!, $status: IntegrationStatus) {
    updateIntegrationUserSettings(id: $id, userSettings: $userSettings, status: $status) {
      id
      type
      connected
      status
      externalId
      userSettings
    }
  }
`)

export const getUserSettingsQuery = gql(/* GraphQL */ `
  query GetUserSettings($settings: [String!]!) {
    getUserSettings(settings: $settings)
  }
`)

export const updateUserSettings = gql(/* GraphQL */ `
  mutation UpdateUserSettings($settings: JSON!) {
    updateUserSettings(settings: $settings)
  }
`)

export const getConnectedSlackChannelsQuery = gql(/* GraphQL */ `
  query GetSlackChannels {
    slackChannels {
      id
      name
    }
  }
`)

export const REVOKE_SLACK_AUTH_MUTATION = gql(/* GraphQL */ `
  mutation RevokeSlackAuth {
    revokeSlackAppAuth
  }
`)

export const getApiKeysQuery = gql(/* GraphQL */ `
  query GetApiKeys($includeDisabled: Boolean) {
    apiKeys(includeDisabled: $includeDisabled) {
      label
      key
      createdAt
      isDisabled
      updatedAt
      integration {
        type
      }
    }
  }
`)

export const getAllUsers = gql(/* GraphQL */ `
  query AllUsers {
    allUsers {
      id
      name
      email
      userProfile {
        userId
        picUrl
      }
    }
  }
`)

export const getAssignableActorList = gql(/* GraphQL */ `
  query GetAssignableActorList($meetingId: ID) {
    getAssignableActorList(meetingId: $meetingId) {
      id
      name
      email
      picUrl
      userId
      attendeeId
    }
  }
`)

export const getAssignableActor = gql(/* GraphQL */ `
  query GetAssignableActor($id: ID!) {
    getAssignableActor(id: $id) {
      id
      name
      email
      picUrl
      userId
      attendeeId
    }
  }
`)

export const inviteUserForSharingMutation = gql(/* GraphQL */ `
  mutation InviteUserForSharing($data: InviteUserForSharingInput!) {
    inviteUserForSharing(data: $data) {
      id
      name
      email
      userProfile {
        picUrl
      }
    }
  }
`)

export const getUser = gql(/* GraphQL */ `
  query User($id: ID!) {
    user(id: $id) {
      id
      name
      email
      userProfile {
        userId
        picUrl
      }
    }
  }
`)

export const getMyOnboardingInfo = gql(/* GraphQL */ `
  query MyOnboardingInfoQuery {
    myOnboardingInfo {
      id
      orgName
      follows
      followsTopics
      orgId
    }
  }
`)

export const getDelegateInfo = gql(/* GraphQL */ `
  query GetDelegateInfo {
    getDelegateInfo {
      delegationEnabled
      activeSessions {
        id
        targetId
        updatedAt
        expiresAt
      }
      delegateHosts {
        id
        email
        orgId
        name
      }
      userDelegates {
        id
        email
        orgId
        name
      }
    }
  }
`)

export const SWITCH_TO_DELEGATE_SESSION_MUTATION = gql(/* GraphQL */ `
  mutation SwitchToDelegateSession($targetId: ID!, $fromRootSession: Boolean) {
    switchToDelegateSession(targetId: $targetId, fromRootSession: $fromRootSession) {
      delegateToken {
        token
        renewalToken
      }
    }
  }
`)

export const removeDelegatedUsersMutation = gql(/* GraphQL */ `
  mutation RemoveDelegatedUsers($userIds: [ID!]!) {
    removeDelegatedUsers(userIds: $userIds)
  }
`)

export const getOnboardingTopics = gql(/* GraphQL */ `
  query GetOnboardingTopics {
    onboardingTopics {
      name
      description
    }
  }
`)

export const getFeedQuery = gql(/* GraphQL */ `
  query FeedQuery($filter: FeedFilterInput, $cursor: ID, $searchTerms: String, $take: Int) {
    feed(filter: $filter, cursor: $cursor, searchTerms: $searchTerms, take: $take) {
      nextCursor
      remaining
      posts {
        externalOrgId
        authorId
        createdAt
        meetingStartOrPostCreateTime
        id
        title
        updatedAt
        postSource
        isPrivate
        isPublished
        publishedAt
        author {
          id
          name
          userProfile {
            userId
            picUrl
          }
        }
        shareDetails {
          id
          slug
          sharedByUserId
        }
        tags {
          name
          id
        }
        actionItemCount
        projects {
          id
          name
        }
        meetingAttendeeEmails
      }
    }
  }
`)

// This matches the type of the response from getFeedQuery
// but it fetches a single post by the share slug or id
export const getPost = gql(/* GraphQL */ `
  query Post($id: String, $slug: String, $summaryId: String) {
    post(id: $id, slug: $slug, summaryId: $summaryId) {
      author {
        id
        name
        userProfile {
          userId
          picUrl
        }
      }
      externalOrgId
      authorId
      content
      jsonContent
      jsonContentVer
      createdAt
      meetingStartOrPostCreateTime
      id
      title
      updatedAt
      postSource
      details
      sourceUrl
      ratingForPost
      isPrivate
      meetingId
      isPublished
      publishedAt
      reactions {
        authorId
        id
        reaction
        author {
          id
          name
        }
      }
      comments {
        id
        createdAt
        updatedAt
        content
        authorId
      }
      shareDetails {
        id
        slug
        sharedByUserId
      }
      tags {
        name
        id
      }
    }
  }
`)

/**
 * Returns a lightweight post object meant for lists and search results
 */
export const getPostListQuery = gql(/* GraphQL */ `
  query getPostList($projectId: ID) {
    getPostList(projectId: $projectId) {
      id
      title
      authorId
      author {
        email
        id
        name
        userProfile {
          picUrl
        }
      }
      shortContent
      postSource
      createdAt
      updatedAt
      meetingStartOrPostCreateTime
      tags {
        id
        name
      }
    }
  }
`)

/**
 * Converts a public post slug to a post ID
 */
export const GET_POST_ID_FOR_SLUG_QUERY = gql(/* GraphQL */ `
  query GetPostIdForSlug($slug: String!) {
    getPostIdForSlug(slug: $slug)
  }
`)

export const getPostVisibilityAndAccess = gql(/* GraphQL */ `
  query GetPostAccessAndVisibility($postId: ID!) {
    getPostAccessAndVisibility(postId: $postId) {
      owner
      sharedWithUsers
      visibleToEveryoneInOrg
      users {
        id
        email
        orgId
        name
        picUrl
      }
    }
  }
`)

export const updatePostVisibilityAndAccess = gql(/* GraphQL */ `
  mutation UpdatePostAccessAndVisibility($postId: ID!, $visibilityRules: PostVisibilityInput) {
    updatePostAccessAndVisibility(postId: $postId, visibilityRules: $visibilityRules) {
      owner
      sharedWithUsers
      visibleToEveryoneInOrg
      users {
        id
        email
        orgId
        name
        picUrl
      }
    }
  }
`)

// This matches the type of the response from getFeedQuery
// but it fetches a single post by the share slug or id
export const getSharedPost = gql(/* GraphQL */ `
  query SharedPost($slug: String!) {
    sharedPost(slug: $slug) {
      id
      author {
        name
        userProfile {
          picUrl
        }
      }
      content
      jsonContent
      jsonContentVer
      createdAt
      meetingStartOrPostCreateTime
      title
      updatedAt
      postSource
      details
    }
  }
`)

export const createPostComment = gql(/* GraphQL */ `
  mutation CreatePostComment($postId: String!, $content: String!) {
    createPostComment(postId: $postId, content: $content) {
      id
      postId
      authorId
      content
      createdAt
      updatedAt
    }
  }
`)

export const DELETE_POST_MUTATION = gql(/* GraphQL */ `
  mutation DeletePost($postId: ID!) {
    deletePost(postId: $postId)
  }
`)

export const sharePost = gql(/* GraphQL */ `
  mutation SharePost($id: String!) {
    sharePost(id: $id) {
      id
      slug
      createdAt
      updatedAt
      sharedByUserId
      postId
    }
  }
`)

export const stopSharingPost = gql(/* GraphQL */ `
  mutation StopSharingPost($id: String!) {
    stopSharingPost(id: $id)
  }
`)

export const deletePostComment = gql(/* GraphQL */ `
  mutation DeletePostComment($id: String!) {
    deletePostComment(commentId: $id)
  }
`)

export const createPostReaction = gql(/* GraphQL */ `
  mutation CreatePostReaction($data: PostReactionUpdate!) {
    createPostReaction(data: $data) {
      id
      reaction
      author {
        id
      }
    }
  }
`)

export const updateOnboardingInfo = gql(/* GraphQL */ `
  mutation UpsertOnboarding($data: OnboardingInfoUpdate!) {
    upsertOnboarding(data: $data) {
      id
      follows
      followsTopics
      orgName
    }
  }
`)

export const deletePostReaction = gql(/* GraphQL */ `
  mutation DeletePostReaction($id: String!) {
    deletePostReaction(id: $id)
  }
`)

// export const getLinkPreview = gqlOld`
// 	query GetLinkPreview($url: String!) {
// 		linkPreview(url: $url)
// 	}
// `

export const postUpdatedSubscription = gql(/* GraphQL */ `
  subscription Subscription {
    postUpdated {
      postId
      operation
    }
  }
`)

export const integrationUpdatedSubscription = gql(/* GraphQL */ `
  subscription integrationUpdatedSubscription {
    integrationUpdated
  }
`)

export const meetingSummaryUpdatedSubscription = gql(/* GraphQL */ `
  subscription MeetingSummaryUpdatedSubscription {
    meetingSummaryUpdated
  }
`)

export const actionItemsUpdated = gql(/* GraphQL */ `
  subscription ActionItemsUpdated {
    actionItemsUpdated {
      postId
      summaryId
      actionItemId
      operation
    }
  }
`)

export const recordingStatusUpdatedSubscription = gql(/* GraphQL */ `
  subscription RecordingStatusUpdatedSubscription {
    recordingStatusUpdated {
      status
    }
  }
`)

export const getMeetingRecording = gql(/* GraphQL */ `
  query MeetingRecording($postId: ID, $summaryId: ID, $meetingId: ID, $slug: ID) {
    meetingRecording(postId: $postId, summaryId: $summaryId, meetingId: $meetingId, slug: $slug) {
      id
      recordingStatus
      summaryStatus
      meetingBotId
      meetingId
      createdAt
      updatedAt
      recordingStart
      recordingEnd
      dataDisposition
      meeting {
        id
        title
        startsAt
      }
      scheduled
      meetingPlatformTitle
    }
  }
`)

export const getMeetingRecordings = gql(/* GraphQL */ `
  query MeetingRecordings($meetingId: String, $includeCompleted: Boolean, $includeScheduled: Boolean) {
    meetingRecordings(meetingId: $meetingId, includeCompleted: $includeCompleted, includeScheduled: $includeScheduled) {
      id
      recordingStatus
      summaryStatus
      meetingBotId
      meetingId
      createdAt
      updatedAt
      recordingStart
      recordingEnd
      dataDisposition
      meeting {
        id
        title
        startsAt
      }
      scheduled
      meetingPlatformTitle
    }
  }
`)

// get the recording state for a meeting
export const getMeetingRecordingState = gql(/* GraphQL */ `
  query MeetingRecordingState($meetingId: ID!) {
    meetingRecordingState(meetingId: $meetingId) {
      meetingId
      status
      recorders {
        meetingRecordingId
        meetingBotStatus
        recallBotId
        summaryStatus
        startedAt
        endedAt
        ownerId
      }
      scheduledStartTime
      scheduledEndTime
    }
  }
`)

export const getMeetingAttendees = gql(/* GraphQL */ `
  query MeetingAttendees($postId: ID, $summaryId: ID, $meetingId: ID) {
    meetingAttendees(meetingId: $meetingId, postId: $postId, summaryId: $summaryId) {
      id
      email
      displayName
    }
  }
`)

export const getMeetingAttendee = gql(/* GraphQL */ `
  query MeetingAttendee($id: ID) {
    meetingAttendee(id: $id) {
      id
      email
      displayName
    }
  }
`)

/**
 * Get a meeting transcript by the PostId
 * or if it is still in draft state, by SummaryId
 */
export const getMeetingTranscription = gql(/* GraphQL */ `
  query getMeetingTranscription($postId: ID, $summaryId: ID, $slug: ID) {
    meetingTranscription(postId: $postId, summaryId: $summaryId, slug: $slug) {
      id
      externalMeetingId
      text
      createdAt
      updatedAt
      recordingStart
      recordingEnd
      createdByUserId
      dataDisposition
      lines {
        speaker
        text
        start
      }
    }
  }
`)

/**
 * Get all action items by postId or summaryId
 */
export const getActionItems = gql(/* GraphQL */ `
  query ActionItems($postId: ID, $summaryId: ID, $actionItemId: ID, $meetingSeriesId: ID, $projectId: ID) {
    actionItems(
      postId: $postId
      summaryId: $summaryId
      actionItemId: $actionItemId
      meetingSeriesId: $meetingSeriesId
      projectId: $projectId
    ) {
      projects {
        id
        name
        orgId
        role
      }
      assignees {
        id
        name
        email
        picUrl
        userId
        attendeeId
      }
      actionItems {
        id
        summaryId
        postId
        meetingId
        ownerType
        ownerId
        ownerFreeformText
        description
        status
        importance
        urgent
        keyDate
        completionDate
        createdAt
        updatedAt
        reporterId
        projectId
      }
    }
  }
`)

/**
 * Create a single Action Item by postId or summaryId
 */
export const createActionItemMutation = gql(/* GraphQL */ `
  mutation CreateActionItem($actionItem: CreateActionItemInput!) {
    createActionItem(actionItem: $actionItem) {
      id
      summaryId
      ownerType
      ownerId
      ownerFreeformText
      description
      status
      importance
      urgent
      keyDate
      completionDate
      createdAt
      updatedAt
      postId
      projectId
    }
  }
`)

/**
 * Update a list of Action Items by postId or summaryId
 */
export const updateActionItemsMutation = gql(/* GraphQL */ `
  mutation UpdateActionItems($actionItems: [UpdateActionItemInput!]!) {
    updateActionItems(actionItems: $actionItems) {
      projects {
        id
        name
        orgId
        role
      }
      assignees {
        id
        name
        email
        picUrl
        userId
        attendeeId
      }
      actionItems {
        id
        summaryId
        postId
        meetingId
        ownerType
        ownerId
        ownerFreeformText
        description
        status
        importance
        urgent
        keyDate
        completionDate
        createdAt
        updatedAt
        reporterId
        projectId
      }
    }
  }
`)

export const deleteActionItemsMutation = gql(/* GraphQL */ `
  mutation DeleteActionItems($actionItemIds: [ID!]!) {
    deleteActionItems(actionItemIds: $actionItemIds)
  }
`)

// get all the meeting summary drafts owned by the user
export const getMeetingSummaryDrafts = gql(/* GraphQL */ `
  query MeetingSummaryDrafts {
    meetingSummaryDrafts {
      id
      summaryId
      content
      title
      metaInfo
      createdAt
      updatedAt
      postId
    }
  }
`)

// Get a specific meeting summary by summary id
export const getMeetingSummaryDraft = gql(/* GraphQL */ `
  query GetMeetingSummaryDraft($summaryId: String!) {
    meetingSummaryById(summaryId: $summaryId) {
      id
      summaryId
      meetingId
      content
      title
      metaInfo
      createdAt
      updatedAt
      postId
    }
  }
`)

// Publish a meeting summary as a post: this returns a post object, not a meeting summary
export const PUBLISH_POST_MUTATION = gql(/* GraphQL */ `
  mutation PublishPost($postId: ID!, $visibilityRules: PostVisibilityInput!) {
    publishPost(postId: $postId, visibilityRules: $visibilityRules) {
      author {
        id
        name
        userProfile {
          userId
          picUrl
        }
      }
      content
      id
      title
      tags {
        id
        name
      }
      postSource
      createdAt
      updatedAt
      details
      isPrivate
    }
  }
`)

export const updatePostSummaryContent = gql(/* GraphQL */ `
  mutation UpdatePostSummaryContent($postId: ID!, $summary: String, $jsonContent: JSON) {
    updatePostContent(postId: $postId, summary: $summary, jsonContent: $jsonContent) {
      id
      content
      jsonContent
      updatedAt
    }
  }
`)

export const updatePostTitle = gql(/* GraphQL */ `
  mutation UpdatePostTitle($postId: ID!, $title: String) {
    updatePostContent(postId: $postId, title: $title) {
      id
      title
      updatedAt
    }
  }
`)

export const LEGACY_UPDATE_POST_IN_EDITOR_MUTATION = gql(/* GraphQL */ `
  mutation LegacyUpdatePostInEditor($postId: ID!, $title: String, $content: String, $tags: [TagInput!]!) {
    updatePostContent(postId: $postId, title: $title, summary: $content) {
      id
      title
      content
      updatedAt
    }
    setPostTagsLegacy(postId: $postId, tags: $tags)
  }
`)

export const GET_VIDEO_RECORDING_URL_QUERY = gql(/* GraphQL */ `
  query RecordingVideoUrl($postId: ID, $summaryId: ID, $streamable: Boolean!) {
    recordingVideoUrl(postId: $postId, summaryId: $summaryId, streamable: $streamable) {
      url
    }
  }
`)

export const getAvailableTags = gql(/* GraphQL */ `
  query GetAvailableTags {
    getAvailableTags {
      id
      name
      orgId
      creatorId
      createdAt
      updatedAt
    }
  }
`)

export const addPostTag = gql(/* GraphQL */ `
  mutation AddPostTag($postId: ID!, $name: String!, $tagId: ID) {
    addPostTag(postId: $postId, name: $name, tagId: $tagId) {
      id
      name
      orgId
      creatorId
      createdAt
      updatedAt
    }
  }
`)

export const removePostTag = gql(/* GraphQL */ `
  mutation RemovePostTag($postId: ID!, $tagId: ID!) {
    removePostTag(postId: $postId, tagId: $tagId)
  }
`)

export const editTag = gql(/* GraphQL */ `
  mutation EditTag($id: String!, $name: String!) {
    updateTag(id: $id, name: $name) {
      id
      name
      creatorId
    }
  }
`)

// Calendar queries

export const meetingSeriesListQuery = gql(/* GraphQL */ `
  query MeetingSeriesList {
    meetingSeriesList {
      id
      title
      createdAt
      updatedAt
      organizerAttendee {
        id
        email
        displayName
      }
    }
  }
`)

export const GET_RECURRING_MEETING_LIST_QUERY = gql(/* GraphQL */ `
  query recurringMeetingList {
    recurringMeetingList {
      id
      title
      createdAt
      updatedAt
      category
      nextMeetingId
      nextMeetingStartsAt
      userPrefs {
        meetingSeriesId
        recordingEnabled
        autoShareAudience
        autoPublishAudience
        isFavorite
        isHidden
        updatedAt
      }
      recurringEventIds
      organizerAttendee {
        id
        email
        displayName
      }
    }
  }
`)

export const UPDATE_RECURRING_MEETING_USER_PREFS_MUTATION = gql(/* GraphQL */ `
  mutation UpdateRecurringMeetingUserPrefs($id: ID!, $prefs: RecurringMeetingUserPrefsInput!) {
    updateRecurringMeetingUserPrefs(id: $id, prefs: $prefs) {
      meetingSeriesId
      recordingEnabled
      autoPublishAudience
      autoShareAudience
      isFavorite
      isHidden
      updatedAt
    }
  }
`)

export const UPDATE_RECURRING_MEETING_CATEGORY_MUTATION = gql(/* GraphQL */ `
  mutation updateRecurringMeetingCategory($id: ID!, $category: String!) {
    updateRecurringMeetingCategory(id: $id, category: $category)
  }
`)

export const meetingSeriesOverviewQuery = gql(/* GraphQL */ `
  query MeetingSeriesOverview($id: ID, $meetingId: ID) {
    meetingSeriesOverview(id: $id, meetingId: $meetingId) {
      id
      title
      createdAt
      updatedAt
      organizerAttendee {
        id
        email
        displayName
      }
    }
  }
`)

export const meetingSeriesDetailPageQuery = gql(/* GraphQL */ `
  query MeetingSeries($id: ID!) {
    actionItems(actionItemId: null, summaryId: null, postId: null, meetingSeriesId: $id) {
      assignees {
        id
        name
        email
        picUrl
        userId
        attendeeId
      }
      actionItems {
        id
        summaryId
        postId
        meetingId
        ownerType
        ownerId
        ownerFreeformText
        description
        status
        importance
        urgent
        keyDate
        completionDate
        createdAt
        updatedAt
        reporterId
      }
    }
    meetingSeries(id: $id) {
      id
      title
      createdAt
      updatedAt
      attendees {
        id
        email
        displayName
      }
      meetings {
        id
        title
        startsAt
        endsAt
        createdAt
        updatedAt
        meetingSeriesId
        attendeeStatuses {
          id
          acceptedInvite
          isOrganizer
        }
        posts {
          id
          title
          createdAt
          updatedAt
          authorId
          isPublished
          actionItemCount
          actionItemCompletedCount
        }
      }
    }
    meetingSeriesTopicInsights(meetingSeriesId: $id) {
      id
      content
      meetingIds
      version
      createdAt
      updatedAt
      rawTopics {
        id
        name
        meetingId
        meetingStartDate
      }
    }
  }
`)

export const calendarEventsQuery = gql(/* GraphQL */ `
  query CalendarEvents($meetingSeriesId: ID) {
    calendarEvents(meetingSeriesId: $meetingSeriesId) {
      events {
        id
        updatedAt
        recordMeeting
        meetingHtmlBody
        meetingUrl
        meetingPlatform
        meeting {
          id
          meetingSeriesId
          title
          startsAt
          endsAt
          createdAt
          updatedAt
          attendeeStatuses {
            id
            acceptedInvite
            isOrganizer
          }
        }
        meetingBots {
          id
          type
        }
      }
      attendees {
        id
        email
        displayName
      }
    }
  }
`)

export const updateCalendarEventRecordingStatusMutation = gql(/* GraphQL */ `
  mutation UpdateCalendarEventRecordingStatus($calendarEventId: ID!, $recordMeeting: Boolean!) {
    updateCalendarEventRecordingStatus(calendarEventId: $calendarEventId, recordMeeting: $recordMeeting)
  }
`)

export const updateUserProfileMutation = gql(/* GraphQL */ `
  mutation UpdateUserProfile($name: String) {
    updateUserProfile(name: $name) {
      email
      id
      orgId
      name
      userProfile {
        userId
        firstname
        lastname
        picUrl
        createdAt
        updatedAt
      }
    }
  }
`)

export const createProjectMutation = gql(/* GraphQL */ `
  mutation CreateProject($name: String!, $description: String) {
    createProject(name: $name, description: $description) {
      id
      orgId
      name
      description
      createdAt
      updatedAt
      health
    }
  }
`)

export const updateProjectNameMutation = gql(/* GraphQL */ `
  mutation UpdateProjectName($id: ID!, $name: String) {
    updateProjectContent(id: $id, name: $name) {
      id
      name
      description
      createdAt
      updatedAt
      health
      status
    }
  }
`)

export const updateProjectDescriptionMutation = gql(/* GraphQL */ `
  mutation UpdateProjectDescription($id: ID!, $description: String) {
    updateProjectContent(id: $id, description: $description) {
      id
      name
      description
      createdAt
      updatedAt
      health
    }
  }
`)

export const updateProjectHealthMutation = gql(/* GraphQL */ `
  mutation UpdateProjectHealth($id: ID!, $health: ProjectHealth!) {
    updateProjectHealth(id: $id, health: $health) {
      id
      name
      description
      createdAt
      updatedAt
      health
      status
    }
  }
`)

export const updateProjectStatusMutation = gql(/* GraphQL */ `
  mutation UpdateProjectStatus($id: ID!, $status: ProjectStatus!) {
    updateProjectStatus(id: $id, status: $status) {
      id
      name
      description
      createdAt
      updatedAt
      health
      status
    }
  }
`)

export const getProjectDetailsQuery = gql(/* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      orgId
      description
      health
      status
      createdAt
      updatedAt
      postCount
      actionItemCount
      actionItemInProgressCount
      actionItemCompletedCount
      role
    }
  }
`)

export const GET_PROJECT_SLIM_QUERY = gql(/* GraphQL */ `
  query GetProjectSlim($id: ID!) {
    getProjectSlim(id: $id) {
      id
      name
      orgId
      role
    }
  }
`)

export const getProjectsQuery = gql(/* GraphQL */ `
  query GetProjects {
    getProjects {
      id
      orgId
      name
      description
      health
      status
      createdAt
      updatedAt
      postCount
      actionItemCount
      actionItemInProgressCount
      actionItemCompletedCount
      role
    }
  }
`)

export const getProjectVisibilityAndAccess = gql(/* GraphQL */ `
  query GetProjectVisibilityAndAccess($projectId: ID!) {
    getProjectVisibilityAndAccess(projectId: $projectId) {
      members {
        userId
        email
        orgId
        name
        picUrl
        role
      }
    }
  }
`)

export const addProjectAclMember = gql(/* GraphQL */ `
  mutation AddProjectAclMember($projectId: ID!, $email: String, $userId: ID, $role: ACLMemberRole!) {
    addProjectAclMember(projectId: $projectId, email: $email, userId: $userId, role: $role) {
      members {
        userId
        email
        orgId
        name
        picUrl
        role
      }
    }
  }
`)

export const updateProjectAclMember = gql(/* GraphQL */ `
  mutation UpdateProjectAclMember($projectId: ID!, $userId: ID!, $role: ACLMemberRole!) {
    updateProjectAclMember(projectId: $projectId, userId: $userId, role: $role) {
      members {
        userId
        email
        orgId
        name
        picUrl
        role
      }
    }
  }
`)

export const removeProjectAclMember = gql(/* GraphQL */ `
  mutation RemoveProjectAclMember($projectId: ID!, $userId: ID!) {
    removeProjectAclMember(projectId: $projectId, userId: $userId) {
      members {
        userId
        email
        orgId
        name
        picUrl
        role
      }
    }
  }
`)

export const getAttachableProjectsQuery = gql(/* GraphQL */ `
  query GetAttachableProjects {
    getProjects {
      id
      name
      orgId
      role
    }
  }
`)

export const deleteProjectMutation = gql(/* GraphQL */ `
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id)
  }
`)

export const getAttachedProjects = gql(/* GraphQL */ `
  query GetAttachedProjects($type: ProjectAttachmentResourceType!, $resourceId: ID!) {
    getAttachedProjects(type: $type, resourceId: $resourceId) {
      id
      name
      orgId
      role
    }
  }
`)

export const attachToProjectMutation = gql(/* GraphQL */ `
  mutation AttachToProject($projectId: ID!, $type: ProjectAttachmentResourceType!, $resourceId: ID!) {
    attachToProject(type: $type, resourceId: $resourceId, projectId: $projectId)
  }
`)

export const detachFromProjectMutation = gql(/* GraphQL */ `
  mutation DetachFromProject($projectId: ID!, $type: ProjectAttachmentResourceType!, $resourceId: ID!) {
    detachFromProject(type: $type, resourceId: $resourceId, projectId: $projectId)
  }
`)

export const PROJECT_UPDATED_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription ProjectUpdated($id: ID!) {
    projectUpdated(id: $id) {
      projectId
      area
    }
  }
`)

// AI Chat
export const GET_AI_CHAT_THREAD_LIST_QUERY = gql(/* GraphQL */ `
  query getAiChatThreadList($context: ThreadContextInput!) {
    getAiChatThreadList(context: $context) {
      id
      title
      contextId
      createdAt
      updatedAt
      isPrivate
      userId
    }
  }
`)

export const GET_AI_CHAT_THREAD_QUERY = gql(/* GraphQL */ `
  query getAiChatThread($threadId: ID!) {
    getAiChatThread(threadId: $threadId) {
      id
      resourceId
      title
      createdAt
      updatedAt
      status
      isPrivate
      userId
      messages {
        id
        role
        content
        createdAt
        runId
        metaData
      }
    }
  }
`)

export const CREATE_AI_CHAT_THREAD_MUTATION = gql(/* GraphQL */ `
  mutation createAiChatThread($prompt: String!, $context: ThreadContextInput, $options: ThreadRunOptionsInput) {
    createAiChatThread(prompt: $prompt, context: $context, options: $options) {
      id
      title
      resourceId
      createdAt
      updatedAt
      status
      isPrivate
      userId
      messages {
        id
        role
        content
        createdAt
        runId
        metaData
      }
    }
  }
`)

export const AI_CHAT_THREAD_UPDATE_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription aiChatThreadUpdate($threadId: ID!) {
    aiChatThreadUpdate(threadId: $threadId) {
      tId
      e
      msgDelta {
        mId
        deltas {
          index
          value
        }
      }
      message {
        id
        role
        content
        createdAt
        runId
        metaData
      }
    }
  }
`)

export const SEND_AI_CHAT_MESSAGE_MUTATION = gql(/* GraphQL */ `
  mutation sendAiChatThreadMessage($threadId: ID!, $content: String, $options: ThreadRunOptionsInput) {
    sendAiChatThreadMessage(threadId: $threadId, content: $content, options: $options) {
      id
      resourceId
      title
      createdAt
      updatedAt
      status
      isPrivate
      userId
      messages {
        id
        role
        content
        createdAt
        runId
        metaData
      }
    }
  }
`)

export const RUN_AI_CHAT_THREAD_MUTATION = gql(/* GraphQL */ `
  mutation runAiChatThread($threadId: ID!, $options: ThreadRunOptionsInput) {
    runAiChatThread(threadId: $threadId, options: $options) {
      id
      resourceId
      title
      createdAt
      updatedAt
      status
      isPrivate
      userId
      messages {
        id
        role
        content
        createdAt
        runId
        metaData
      }
    }
  }
`)

export const DELETE_AI_CHAT_THREAD_MUTATION = gql(/* GraphQL */ `
  mutation deleteAiChatThread($threadId: ID!) {
    deleteAiChatThread(threadId: $threadId)
  }
`)

export const GET_PERSONAL_PROMPT_LIST_QUERY = gql(/* GraphQL */ `
  query getPersonalPromptList {
    getPersonalPromptList {
      id
      name
      prompt
      isQuickAction
      createdAt
      updatedAt
    }
  }
`)

export const CREATE_PERSONAL_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation createPersonalPrompt($name: String!, $prompt: String!, $isQuickAction: Boolean!) {
    createPersonalPrompt(name: $name, prompt: $prompt, isQuickAction: $isQuickAction) {
      id
      name
      prompt
      isQuickAction
      createdAt
      updatedAt
    }
  }
`)

export const UPDATE_PERSONAL_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation updatePersonalPrompt($id: ID!, $name: String, $prompt: String, $isQuickAction: Boolean) {
    updatePersonalPrompt(id: $id, name: $name, prompt: $prompt, isQuickAction: $isQuickAction) {
      id
      name
      prompt
      isQuickAction
      createdAt
      updatedAt
    }
  }
`)

export const DELETE_PERSONAL_PROMPT_MUTATION = gql(/* GraphQL */ `
  mutation deletePersonalPrompt($id: ID!) {
    deletePersonalPrompt(id: $id)
  }
`)

export const UPDATE_THREAD_ACCESS_MUTATION = gql(/* GraphQL */ `
  mutation updateAiChatThreadAccess($threadId: ID!, $isPrivate: Boolean!) {
    updateAiChatThreadAccess(threadId: $threadId, isPrivate: $isPrivate) {
      id
      resourceId
      title
      createdAt
      updatedAt
      status
      isPrivate
      userId
      messages {
        id
        role
        content
        createdAt
        runId
      }
    }
  }
`)

export const MERGE_MEETING_SERIES_MUTATION = gql(/* GraphQL */ `
  mutation MergeMeetingSeries($input: MergeMeetingSeriesInput!) {
    mergeMeetingSeries(input: $input)
  }
`)

export const GET_MEETING_SERIES_MERGE_CONTEXT_QUERY = gql(/* GraphQL */ `
  query GetMeetingSeriesMergeContext($meetingSeriesIds: [ID!]!) {
    getMeetingSeriesMergeContext(meetingSeriesIds: $meetingSeriesIds) {
      baseMeetingSeries {
        id
        title
        createdAt
        category
        userPrefs {
          recordingEnabled
          autoPublishAudience
          autoShareAudience
        }
        owner {
          id
          name
          email
        }
      }
      otherMeetingSeries {
        id
        title
        createdAt
        category
        userPrefs {
          recordingEnabled
          autoPublishAudience
          autoShareAudience
        }
        owner {
          id
          name
          email
        }
      }
    }
  }
`)

export const QUICK_SEARCH_QUERY = gql(/* GraphQL */ `
  query QuickSearch($query: String!, $limit: Int!) {
    quickSearchProjects(query: $query, limit: $limit) {
      id
      name
      health
      status
      createdAt
      updatedAt
      owner {
        id
        name
        picUrl
      }
    }
    quickSearchPosts(query: $query, limit: $limit) {
      id
      title
      postSource
      createdAt
      updatedAt
      author {
        id
        name
        picUrl
      }
    }
  }
`)
